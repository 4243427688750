import React from 'react';

import { useSearch } from '../../../hooks/context';
import { useStaticProps } from '../../../components/context/static-props';

const namespace = 'ui-category-trends-header';

const CategoriesHeader = () => {
  const { seo, header } = useSearch();
  const { deviceType } = useStaticProps();

  const categoryName = header?.subtitle || seo.h1;

  return (
    <div className={`${namespace}-title-container ${namespace}-${deviceType}-title-container`}>
      <h1 className={`${namespace}-title ${namespace}-${deviceType}-title`}>
        {header?.title}
        <b className={`${namespace}-title-category-name`}>{categoryName}</b>
      </h1>
    </div>
  );
};

export default CategoriesHeader;
